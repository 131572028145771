export const actions = {
  toggle() {},
};

export const mutations = {
  select(state, airport) {
    state.airport = airport;
  },
};

export const state = () => ({
  airport: {},
});
