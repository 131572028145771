/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { getInstance as getBffInstance } from '~/services/bffService';
import Fuse from 'fuse.js';

const state = () => ({
  searchQuery: '',
  searchResults: [],
  selectedTopic: null,
  topics: [],
});

const mutations = {
  async updateTopics(state, payload) {
    state.topics = [];

    if (payload && payload.length) {
      state.topics = payload;
    }
  },
  async setSelectedTopic(state, payload) {
    const { topics } = state;

    if (state.searchQuery) {
      state.searchQuery = null;
    }

    if (payload) {
      state.selectedTopic = payload;
    } else if (topics && topics.length) {
      state.selectedTopic = topics[0];
    }
  },
  async updateSearchQuery(state, payload) {
    state.searchQuery = payload || null;
  },
  async updateSearchResults(state, payload) {
    state.searchResults = [];

    if (payload && payload.length) {
      state.searchResults = payload;
    }
  },
};

const actions = {
  async getTopics({ commit, rootState }) {
    const BFF = getBffInstance('parkos');

    const { lang } = rootState.language;
    const res = await BFF.getFaqTopics(lang);

    await commit('updateTopics', res?.sections);
    await commit('setSelectedTopic');
  },
  async makeSearch({ state, commit }) {
    commit('updateSearchResults');

    const { searchQuery } = state;

    if (searchQuery && searchQuery.length) {
      const articles = state.topics.flatMap((topic) => topic.articles);
      const fuse = new Fuse(articles, {
        ignoreLocation: true,
        minMatchCharLength: searchQuery.length,
        keys: ['title', 'body'],
      });

      const results = fuse.search(searchQuery).map((res) => res.item);
      commit('updateSearchResults', results || []);
    }
  },
};

export { state, mutations, actions };
