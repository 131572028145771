const state = () => ({
  list: [
    {
      domain: 'parkos.com',
      scriptFileName: '69f0ab0b54f36d351801f48f4cf8299e',
      clickId: '40762',
    },
    {
      domain: 'parkos.co.uk',
      scriptFileName: '62063fd13f5fcdc2ba6d2b10a26806f5',
      clickId: '43584',
    },
    {
      domain: 'parkos.com.au',
      scriptFileName: 'f2e1dcf4e9445ab202b0f8d089ed5653',
      clickId: '43585',
    },
    {
      domain: 'parkos.nl',
      scriptFileName: '57c0eb66626cfd18463a0fd4ee55fb14',
      clickId: '43586',
    },
    {
      domain: 'parkos.be',
      scriptFileName: '91085bd8f0cdab7aba6fd5bf1d93d02a',
      clickId: '43588',
    },
    {
      domain: 'parkos.fr',
      scriptFileName: '4577a6aa41add37915c578da2e3261d9',
      clickId: '43589',
    },
    {
      domain: 'parkos.es',
      scriptFileName: '09f1e9ce9a758d48c85ffb48e9a088c7',
      clickId: '43590',
    },
    {
      domain: 'parkos.it',
      scriptFileName: 'b4a362dc136a926c7f8e42a068fbfbe4',
      clickId: '43591',
    },
    {
      domain: 'parkos.se',
      scriptFileName: 'a7c108052bdcbf99b0b4e14ea7f6b582',
      clickId: '43592',
    },
    {
      domain: 'parkos.pl',
      scriptFileName: '71e4526c187bfcc602e40339f40378f2',
      clickId: '43593',
    },
    {
      domain: 'fr.parkos.be',
      scriptFileName: '48de3d2ddef4a2d9899f705b96bedf2b',
      clickId: '43594',
    },
    {
      domain: 'eu.parkos.com',
      scriptFileName: '8cca6e5edee28e1685fe96c10e0bf3c9',
      clickId: '43595',
    },
    {
      domain: 'parkos.de',
      scriptFileName: '424ef4209520f812bf5ea1387aa25f12',
      clickId: '43596',
    },
    {
      domain: 'parkos.pt',
      scriptFileName: '1b6a61605c3201ec6ef6e1fb25bb6e19',
      clickId: '43597',
    },
    {
      domain: 'parkos.at',
      scriptFileName: 'aa98be175be9a6124da013e5c02fe2f7',
      clickId: '43598',
    },
    {
      domain: 'parkos.dk',
      scriptFileName: 'fef0e8a2d4636f32b76579d220ebdcae',
      clickId: '43599',
    },
  ],
});

export {
  // eslint-disable-next-line import/prefer-default-export
  state,
};
