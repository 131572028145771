/* eslint-disable no-empty-pattern */
/* eslint-disable consistent-return */

const list = {
  'parkos.com': {
    fileName: '69f0ab0b54f36d351801f48f4cf8299e',
    clickId: '40762',
  },
  'parkos.co.uk': {
    fileName: '62063fd13f5fcdc2ba6d2b10a26806f5',
    clickId: '43584',
  },
  'parkos.com.au': {
    fileName: 'f2e1dcf4e9445ab202b0f8d089ed5653',
    clickId: '43585',
  },
  'parkos.nl': {
    fileName: '57c0eb66626cfd18463a0fd4ee55fb14',
    clickId: '43586',
  },
  'parkos.be': {
    fileName: '91085bd8f0cdab7aba6fd5bf1d93d02a',
    clickId: '43588',
  },
  'parkos.fr': {
    fileName: '4577a6aa41add37915c578da2e3261d9',
    clickId: '43589',
  },
  'parkos.es': {
    fileName: '09f1e9ce9a758d48c85ffb48e9a088c7',
    clickId: '43590',
  },
  'parkos.it': {
    fileName: 'b4a362dc136a926c7f8e42a068fbfbe4',
    clickId: '43591',
  },
  'parkos.se': {
    fileName: 'a7c108052bdcbf99b0b4e14ea7f6b582',
    clickId: '43592',
  },
  'parkos.pl': {
    fileName: '71e4526c187bfcc602e40339f40378f2',
    clickId: '43593',
  },
  'fr.parkos.be': {
    fileName: '48de3d2ddef4a2d9899f705b96bedf2b',
    clickId: '43594',
  },
  'eu.parkos.com': {
    fileName: '8cca6e5edee28e1685fe96c10e0bf3c9',
    clickId: '43595',
  },
  'parkos.de': {
    fileName: '424ef4209520f812bf5ea1387aa25f12',
    clickId: '43596',
  },
  'parkos.pt': {
    fileName: '1b6a61605c3201ec6ef6e1fb25bb6e19',
    clickId: '43597',
  },
  'parkos.at': {
    fileName: 'aa98be175be9a6124da013e5c02fe2f7',
    clickId: '43598',
  },
  'parkos.dk': {
    fileName: 'fef0e8a2d4636f32b76579d220ebdcae',
    clickId: '43599',
  },
};

export default ({}, inject) => {
  const cheq = (domain) => {
    if (!Object.hasOwnProperty.call(list, domain)) {
      console.log(`${domain} does not have CHEQ configured, continuing without it`);
      return false;
    }

    return {
      script: {
        hid: 'CHEQ',
        src: `https://euob.segreencolumn.com/sxp/i/${list[domain].fileName}.js`,
        'data-ch': domain,
        'data-jsonp': 'onCheqResponse',
        class: `ct_clicktrue_${list[domain].clickId}`,
        async: true,
      },
      noScript: `<iframe src='https://obseu.segreencolumn.com/ns/${list[domain].fileName}.html?ch=${domain}' width='0' height='0' style='display:none'></iframe>`,
    };
  };

  inject('cheq', cheq);
};
