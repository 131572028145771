export default function () {
  window.onNuxtReady(() => {
      const localLink = document.createElement('link')
      localLink.rel = 'stylesheet'
      localLink.href = '/_nuxt_faq_page/css/fonts.css'
      document.head.appendChild(localLink)

      const googleLink = document.createElement('link')
      googleLink.rel = 'stylesheet'
      googleLink.href = 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap'
      document.head.appendChild(googleLink)
  })
}
