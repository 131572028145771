export const actions = {};

export const mutations = {
  update(state, keyValue) {
    // eslint-disable-next-line no-param-reassign
    state[keyValue.key] = keyValue.value;

    if (keyValue.key === 'arrival_date' || keyValue.key === 'departure_date') {
      const dateStart = new Date(state.arrival_date);
      const dateEnd = new Date(state.departure_date);
      state.number_of_days = (dateEnd.getTime() - dateStart.getTime()) / 1000 / 60 / 60 / 24 + 1;
    }
  },
};

export const state = () => ({
  domain: {
    country: '',
    country_code: '',
    language: '',
    name: '',
  },
  page_type: 'faq',
  airport: {
    id: '',
    name: '',
    slug: '',
    commonName: '',
  },
  merchant: {
    name: '',
    id: '',
    slug: '',
  },
  arrival_date: '',
  departure_date: '',
  number_of_days: '',
  review_score: '',
  review_amount: '',
  filter_state: '', // skip
  outbound: '',
  file_extension: '', // skip
  file_name: '', // skip
  error_message: '',
  error_url: '',
  error_line: '',
  currency: '',
  item_category: '',
  item_list_name: '',
  category: '',
  event_interaction: '',
  event_status: '',
  arrival_time: '',
  departure_time: '',
  pickerInteracted: '',
});
