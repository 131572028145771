// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import consola from 'consola';

class BffService {
  constructor(axiosConfig) {
    this.createAxiosInstance(axiosConfig);
  }

  // eslint-disable-next-line class-methods-use-this
  getUserAgent() {
    const appName = 'ParkosFaqPages';
    const projectName = 'faq-pages';
    const { VERSION, ENVIRONMENT } = process.env;

    if (VERSION && ENVIRONMENT) {
      return `${appName}/${VERSION} (+https://${projectName}.${ENVIRONMENT}.parkos.dev/)`;
    }

    return `${appName}/development`;
  }

  createAxiosInstance(axiosConfig) {
    const axiosPayload = axios.create({ baseURL: process.env.BFF_BASE_URL });

    // log https errors
    axiosPayload.interceptors.request.use(
      (request) => {
        request.headers = {
          ...request.headers,
          'User-Agent': this.getUserAgent(),
          ...(axiosConfig.headers || {}),
        };
        return request;
      },
      (error) => {
        consola.error('BFF Request Error: ', JSON.stringify(error));
        return error;
      },
    );

    axiosPayload.interceptors.response.use(
      (response) => response,
      (error) => {
        consola.error('BFF Response Error:  ', JSON.stringify(error));
        return error;
      },
    );

    this.axiosInstance = axiosPayload;
  }

  async getPageContent(devtitle, language) {
    try {
      const res = await this.axiosInstance.get(`pages/${devtitle}/content.json`, {
        params: {
          lang: language,
        },
      });

      return res.data;
    } catch {
      return {};
    }
  }

  async getHeaderAirportsConfiguration() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('configurations/header')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getLanguages() {
    try {
      const res = await this.axiosInstance.get('languages');

      return res.data.data;
    } catch (error) {
      return error;
    }
  }

  async getTranslations(language) {
    try {
      const { data } = await this.axiosInstance.get(`translations/${language}/faq`);
      return data;
    } catch (e) {
      return e;
    }
  }

  async getOpeningTimes(language) {
    try {
      const { data } = await this.axiosInstance.get('customer-service/opening-times.json', {
        params: {
          lang: language,
        },
      });
      return data;
    } catch (e) {
      return e;
    }
  }

  async getAirports(lang, limit = 250, orderBy = 'locations_content.name') {
    try {
      const { data } = await this.axiosInstance.get('airports', {
        params: { lang, limit, orderBy },
      });

      return data.data;
    } catch (e) {
      return e;
    }
  }

  async getPageTemplate(slug, lang) {
    try {
      const { data } = await this.axiosInstance.get('page_template', {
        params: {
          slug,
          lang,
        },
      });
      return data.data[lang];
    } catch (e) {
      return e;
    }
  }

  async getFaqTopics(lang) {
    try {
      const res = await this.axiosInstance.get('zendesk/sections', {
        params: { lang },
      });

      return res?.data;
    } catch (e) {
      return e;
    }
  }

  async getFaqContents(section, lang) {
    try {
      const res = await this.axiosInstance.get('zendesk/articles', {
        params: { section, lang },
      });

      return res?.data;
    } catch (e) {
      return e;
    }
  }

  async searchFaq(query, lang) {
    try {
      const res = await this.axiosInstance.get('zendesk/search', {
        params: { query, lang },
      });

      return res?.data;
    } catch (e) {
      return e;
    }
  }
}

const bffInstances = {};

// eslint-disable-next-line import/prefer-default-export
export function getInstance(name, config) {
  if (!(name in bffInstances)) {
    bffInstances[name] = new BffService(config);
  }

  return bffInstances[name];
}
