/* eslint-disable no-shadow */
import { getInstance as getBffInstance } from '~/services/bffService';

export const state = () => ({
  languages: [],
  language: {},
  airports: [],
  airport: {},
  translations: {},
  pageContent: {},
  modalOpen: false,
  trustpilotUrls: {},
  headerAirportsConfiguration: {},
  openingTimes: {},
});

export const mutations = {
  setModal(state, value) {
    state.modalOpen = value;
  },
};

export const actions = {
  async nuxtServerInit({ state }, { app, req }) {
    const getReferer = () => `https://${app.$paths.urlEnvPrefix}${app.$paths.host}${req.originalUrl}`;

    const BFF = getBffInstance('parkos', {
      headers: {
        Referer: getReferer(),
      },
    });

    state.languages = await BFF.getLanguages();
    state.language = state.languages.find((language) => language.domain === app.$paths.host);
    state.languages = state.languages.map((lang) => ({
      ...lang,
      domain: `${app.$paths.urlEnvPrefix}${lang.domain}`,
    }));

    const airports = await BFF.getAirports(state.language?.lang ?? 'en-us');
    state.airports = airports.filter(
      (airport) => !!state.language?.status && (!!state.language.is_minimal_product || airport.parking_count > 0),
    );
    [state.airport] = state.airports;

    const { lang } = state.language;

    if (!state.language.socials.facebook) {
      delete state.language.socials.facebook;
    }

    if (!state.language.socials.twitter) {
      delete state.language.socials.twitter;
    }

    // If no YouTube link is set in the socials fallback to the default
    if (!state.language.socials.youtube) {
      state.language.socials.youtube = 'https://www.youtube.com/parkosnl';
    }

    state.translations = await BFF.getTranslations(state.language.lang);

    state.openingTimes = await BFF.getOpeningTimes(state.language.lang);

    const slugListForContents = ['faq', 'terms-conditions', 'privacy-policy', 'about-us'];
    const [faqContent, termsContent, privacyPolicyContent, aboutContent] = await Promise.allSettled(
      slugListForContents.map((slug) => BFF.getPageContent(slug, state.language.lang)),
    );
    state.pageContent = {
      faq: faqContent.value && faqContent.value[lang],
      termsConditionsContent: termsContent.value && termsContent.value[lang],
      privacyPolicyContent: privacyPolicyContent.value && privacyPolicyContent.value[lang],
      aboutPageContent: aboutContent.value && aboutContent.value[lang],
    };

    // state.trustpilotUrls = await BFF.getService('trustpilot');
    state.modalOpen = false;

    state.headerAirportsConfiguration = await BFF.getHeaderAirportsConfiguration();
    const slugs = ['footer_airports', 'footer_information', 'footer_about', 'footer_icons'];
    const [airportsTemplate, informationTemplate, aboutTemplate, paymentIconsTemplate] = await Promise.allSettled(
      slugs.map((_slug) => BFF.getPageTemplate(_slug, state.language.lang)),
    );
    state.pageTemplate = {
      footer_airports: airportsTemplate.value,
      footer_information: informationTemplate.value,
      footer_about: aboutTemplate.value,
      footer_icons: paymentIconsTemplate.value,
    };

    const metas = ['faq'];
    const [faqMeta] = await Promise.allSettled(metas.map((meta) => BFF.getPageContent(meta, state.language.lang)));
    state.metaPages = {
      faqMeta: (faqMeta.value && faqMeta.value[state.language.lang]) || undefined,
    };
  },
};

export const strict = false;
